import React from 'react'
import styled from 'styled-components'
import CenterContainer from '../components/CenterContainer'
import Layout from '../components/Layout'
import MonoTitle from '../components/MonoTitle'
import SEO from '../components/SEO'
import faceImage from '../assets/head.svg'

const Face = styled.img`
  min-width: 200px;
  max-width: 300px;
  width: 50%;
  margin-bottom: 30px;
`

const CenterContainerWithPaddingTopForMobile = styled(CenterContainer)`
  @media only screen and (max-height: 570px) {
    margin-top: 60px;
  }
`

const CustomLink = styled.a`
  color: #0b23ff;
`

export default ({ location }) => {
  return (
    <Layout location={location}>
      <SEO title="About me" />
      <CenterContainerWithPaddingTopForMobile>
        <div>
          <Face src={faceImage} />
          <MonoTitle>Hey I’m Håkon</MonoTitle>
          <MonoTitle>I like to do stuff</MonoTitle>
          <MonoTitle>
            Currently I do stuff @{' '}
            <CustomLink href="https://onomondo.com" target="_blank">
              onomondo
            </CustomLink>
          </MonoTitle>
        </div>
      </CenterContainerWithPaddingTopForMobile>
    </Layout>
  )
}
